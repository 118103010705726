<template>
  <div class="Coupons_wrapper">
    <el-tabs type="border-card" v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="卡券订单" name="1">
        <div class="select_wrap">
          <div>
            <span>请输入ID：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入ID搜索 "
              v-model.number="CardOrderInfo.userId"
            ></el-input>
          </div>
          <div>
            <span>请输入名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入姓名搜索 "
              v-model="CardOrderInfo.userName"
            ></el-input>
          </div>
          <div>
            <span>请输入手机号码：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入手机号搜索 "
              v-model="CardOrderInfo.mobile"
            ></el-input>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
          <el-button
            type="success"
            class="ml10"
            icon="el-icon-download"
            @click="exportCardOrderData"
          >导出表格</el-button>
        </div>
        <el-table class="mt20" :data="CardOrderList" border stripe size="mini" style="width: 100%">
          <el-table-column prop="userId" label="客户ID"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column prop="userMobile" label="手机号"></el-table-column>
          <el-table-column prop="blUserName" label="归属人"></el-table-column>
          <el-table-column prop="blBrandName" label="归属人品牌"></el-table-column>
          <el-table-column prop="blShopName" label="归属人门店"></el-table-column>
          <el-table-column prop="bonusAmount" label="奖励发放金额"></el-table-column>
          <el-table-column prop="tradeNo" label="订单ID"></el-table-column>
          <el-table-column prop="payTime" label="支付时间"></el-table-column>
          <el-table-column prop="payAmount" label="支付金额"></el-table-column>
        </el-table>
        <div class="tab-page">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="CardOrderInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="CardOrderTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="优惠券核销记录" name="2">
        <el-alert class="mb20" title="该页面仅展示已核销的用户" type="info" :closable="false"></el-alert>
        <div class="select_wrap">
          <div>
            <span>请输入订单ID：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入ID搜索 "
              v-model="RecordInfo.tradeNo"
            ></el-input>
          </div>
          <div>
            <span>请输入名称：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入姓名搜索 "
              v-model="RecordInfo.userName"
            ></el-input>
          </div>
          <div>
            <span>请输入手机号码：</span>
            <el-input
              class="ml10"
              :clearable="true"
              placeholder="请输入手机号搜索 "
              v-model="RecordInfo.mobile"
            ></el-input>
          </div>
          <div>
            <span>转单类型：</span>
            <el-select v-model="RecordInfo.progressType" placeholder="请选择" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage2">搜索</el-button>
          <el-button
            type="success"
            class="ml10"
            icon="el-icon-download"
            @click="exportRecordData"
          >导出表格</el-button>
        </div>
        <el-table class="mt20" :data="RecordList" border stripe size="mini" style="width: 100%">
          <el-table-column prop="userId" label="客户ID"></el-table-column>
          <el-table-column prop="nickname" label="客户昵称"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column prop="userMobile" label="手机号"></el-table-column>
          <el-table-column prop="blUserName" label="归属人"></el-table-column>
          <el-table-column prop="blBrandName" label="归属人品牌"></el-table-column>
          <el-table-column prop="blShopName" label="归属人门店"></el-table-column>
          <el-table-column prop="cardCouponName" label="优惠券名称"></el-table-column>
          <el-table-column prop="cardCouponBrandName" label="优惠券所属品牌"></el-table-column>
          <el-table-column prop="status" label="核销状态">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.status=='active'">未核销</el-tag>
              <el-tag type="primary" v-if="scope.row.status=='finish'">已核销</el-tag>
              <el-tag type="primary" v-if="scope.row.status=='cancel'">已退单</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="changeAmount" label="过程转单金额"></el-table-column>
          <el-table-column prop="progressType" label="转单类型">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.progressType=='process_devote_order'">贡献</el-tag>
              <el-tag type="primary" v-if="scope.row.progressType=='process_self_order'">自签</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="finishTime" label="核销时间"></el-table-column>
        </el-table>
        <div class="tab-page">
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="RecordInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="RecordTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "Coupons", // 数据统计-营销数据-售券
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      idInput: "",
      options: [
        { value: "process_devote_order", label: "贡献" },
        { value: "process_self_order", label: "自签" },
      ],
      value: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      CardOrderInfo: {
        actId: 0,
        mobile: "",
        page: 1,
        size: 10,
        userId: "",
        userName: "",
      },
      CardOrderList: [],
      CardOrderTotal: 0,
      RecordInfo: {
        actId: 0,
        blUserName: "",
        brandId: "",
        mobile: "",
        page: 1,
        progressType: "",
        size: 10,
        tradeNo: "",
        userName: "",
      },
      RecordList: [],
      RecordTotal: 0,
      tabName: "1",
    };
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.CardOrderInfo.page = 1;
        this.getQueryCardOrderList();
      } else if (this.tabName == "2") {
        this.RecordInfo.page = 1;
        this.getQueryCardCouponRecordList();
      }
    },
    // 卡券订单列表页码请求
    handleCurrentChange(val) {
      this.CardOrderInfo.page = val;
      this.getQueryCardOrderList();
    },
    searchPage() {
      this.CardOrderInfo.page = 1;
      this.getQueryCardOrderList();
    },
    // 优惠券核销记录页码请求
    handleCurrentChange2(val) {
      this.RecordInfo.page = val;
      this.getQueryCardCouponRecordList();
    },
    searchPage2() {
      this.RecordInfo.page = 1;
      this.getQueryCardCouponRecordList();
    },
    // 查询卡券订单列表
    async getQueryCardOrderList() {
      try {
        this.CardOrderInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQueryCardOrderList(this.CardOrderInfo);
        this.CardOrderList = list;
        this.CardOrderTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询优惠券核销记录
    async getQueryCardCouponRecordList() {
      try {
        this.RecordInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQueryCardCouponRecordList(this.RecordInfo);
        this.RecordList = list;
        this.RecordTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出卡券订单列表
    async exportCardOrderData() {
      try {
        const data = await StatisticsAjax.getExportCardOrderList(
          this.CardOrderInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "卡券订单统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出优惠券核销记录
    async exportRecordData() {
      try {
        const data = await StatisticsAjax.getExportCardCouponRecordList(
          this.RecordInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "优惠券核销记录.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryCardOrderList();
    this.getQueryCardCouponRecordList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.Coupons_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
