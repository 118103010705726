var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Coupons_wrapper" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { click: function($event) {}, "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "卡券订单", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入ID：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入ID搜索 "
                        },
                        model: {
                          value: _vm.CardOrderInfo.userId,
                          callback: function($$v) {
                            _vm.$set(_vm.CardOrderInfo, "userId", _vm._n($$v))
                          },
                          expression: "CardOrderInfo.userId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入名称：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入姓名搜索 "
                        },
                        model: {
                          value: _vm.CardOrderInfo.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.CardOrderInfo, "userName", $$v)
                          },
                          expression: "CardOrderInfo.userName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入手机号码：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入手机号搜索 "
                        },
                        model: {
                          value: _vm.CardOrderInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.CardOrderInfo, "mobile", $$v)
                          },
                          expression: "CardOrderInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "success", icon: "el-icon-download" },
                      on: { click: _vm.exportCardOrderData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "mt20",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.CardOrderList,
                    border: "",
                    stripe: "",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userId", label: "客户ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userMobile", label: "手机号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blUserName", label: "归属人" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blBrandName", label: "归属人品牌" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blShopName", label: "归属人门店" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusAmount", label: "奖励发放金额" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "tradeNo", label: "订单ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "payTime", label: "支付时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "payAmount", label: "支付金额" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.CardOrderInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.CardOrderTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "优惠券核销记录", name: "2" } },
            [
              _c("el-alert", {
                staticClass: "mb20",
                attrs: {
                  title: "该页面仅展示已核销的用户",
                  type: "info",
                  closable: false
                }
              }),
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入订单ID：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入ID搜索 "
                        },
                        model: {
                          value: _vm.RecordInfo.tradeNo,
                          callback: function($$v) {
                            _vm.$set(_vm.RecordInfo, "tradeNo", $$v)
                          },
                          expression: "RecordInfo.tradeNo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入名称：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入姓名搜索 "
                        },
                        model: {
                          value: _vm.RecordInfo.userName,
                          callback: function($$v) {
                            _vm.$set(_vm.RecordInfo, "userName", $$v)
                          },
                          expression: "RecordInfo.userName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("请输入手机号码：")]),
                      _c("el-input", {
                        staticClass: "ml10",
                        attrs: {
                          clearable: true,
                          placeholder: "请输入手机号搜索 "
                        },
                        model: {
                          value: _vm.RecordInfo.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.RecordInfo, "mobile", $$v)
                          },
                          expression: "RecordInfo.mobile"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("转单类型：")]),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.RecordInfo.progressType,
                            callback: function($$v) {
                              _vm.$set(_vm.RecordInfo, "progressType", $$v)
                            },
                            expression: "RecordInfo.progressType"
                          }
                        },
                        _vm._l(_vm.options, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage2 }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "success", icon: "el-icon-download" },
                      on: { click: _vm.exportRecordData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "mt20",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.RecordList,
                    border: "",
                    stripe: "",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "userId", label: "客户ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "nickname", label: "客户昵称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userName", label: "姓名" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "userMobile", label: "手机号" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blUserName", label: "归属人" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blBrandName", label: "归属人品牌" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "blShopName", label: "归属人门店" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cardCouponName", label: "优惠券名称" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cardCouponBrandName",
                      label: "优惠券所属品牌"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "status", label: "核销状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == "active"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("未核销")
                                ])
                              : _vm._e(),
                            scope.row.status == "finish"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("已核销")
                                ])
                              : _vm._e(),
                            scope.row.status == "cancel"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("已退单")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "changeAmount", label: "过程转单金额" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "progressType", label: "转单类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.progressType == "process_devote_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("贡献")
                                ])
                              : _vm._e(),
                            scope.row.progressType == "process_self_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("自签")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "finishTime", label: "核销时间" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.RecordInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.RecordTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange2 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }